import React, { useState, useEffect } from 'react';

const CurrentDateTime = () => {
  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    // Очистка таймера при размонтировании компонента
    return () => clearInterval(timer);
  }, []);

  // Форматируем дату и время без секунд
  const formattedDateTime = currentDateTime.toLocaleString('ru-RU', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  });

  return (
    <div>
      <p>{formattedDateTime}</p>
    </div>
  );
};

export default CurrentDateTime;
