import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import './SearchForm.css';
import { createClient } from "@supabase/supabase-js";
import PrintCart from './PrintCart';
import CurrentDateTime from './Component/CurrentDateTime';
import Dashboard from './Component/Dashboard';

const supabase = createClient("https://wqjzhdhebstsjthxsxiq.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndxanpoZGhlYnN0c2p0aHhzeGlxIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjU1NDE3ODAsImV4cCI6MjA0MTExNzc4MH0.yVeRsCkTMuvBjXm08ZzRxOy6XPq2oVMnH52Hv7_2B9g");

const App = () => {
  const [doriData, setDoriData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(true);
  const [cart, setCart] = useState([]);
  const [showPrintCart, setShowPrintCart] = useState(false);
  const inputRef = useRef(null);
  const searchTimeoutRef = useRef(null);
  const lastInputTimeRef = useRef(Date.now());

  useEffect(() => {
    const fetchDoriData = async () => {
      const { data, error } = await supabase.from('dori').select('*');
      if (error) {
        console.error("Ошибка загрузки данных:", error);
      } else {
        setDoriData(data);
      }
      setLoading(false);
    };

    fetchDoriData();
  }, []);

  const handleSearchChange = async (e) => {
    const value = e.target.value;
    setSearchText(value);

    const currentTime = Date.now();
    const timeDiff = currentTime - lastInputTimeRef.current;
    lastInputTimeRef.current = currentTime;

    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    searchTimeoutRef.current = setTimeout(async () => {
      await performSearch(value, timeDiff);
    }, 20);
  };

  const performSearch = async (value, timeDiff) => {
    let query = supabase.from('dori').select('*');

    if (value.trim() === "") {
      const { data, error } = await query;
      if (error) {
        console.error("Ошибка при загрузке всех данных:", error);
      } else {
        setDoriData(data);
        if (timeDiff < 50) {
          setSearchText('');
        }
      }
      return;
    }

    const isNumeric = !isNaN(value);
    if (isNumeric) {
      const numericValue = parseInt(value, 10);
      query = query.eq('kod', numericValue);
    } else {
      query = query.ilike('nom', `%${value}%`);
    }

    const { data, error } = await query;

    if (error) {
      console.error("Ошибка при поиске данных:", error);
    } else {
      setDoriData(data);
      if (timeDiff < 50) {
        setSearchText('');
      }
    }
  };

  const addToCart = (item) => {
    const existingItem = cart.find(cartItem => cartItem.id === item.id);
    if (existingItem) {
      setCart(cart.map(cartItem => cartItem.id === item.id ? { ...cartItem, son: cartItem.son + 1 } : cartItem));
    } else {
      setCart([...cart, { ...item, son: 1 }]);
    }

    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const removeFromCart = (id) => {
    setCart(cart.filter(item => item.id !== id));
  };

  const increaseQuantity = (id) => {
    setCart(cart.map(item => item.id === id ? { ...item, son: item.son + 1 } : item));
  };

  const decreaseQuantity = (id) => {
    setCart(cart.map(item => item.id === id && item.son > 1 ? { ...item, son: item.son - 1 } : item));
  };

  const submitOrder = async () => {
    if (cart.length === 0) {
      alert("Корзина пуста!");
      return;
    }

    const orderData = cart.map(item => ({
      nom: item.nom,
      muddat: new Date(),
      davlat: item.davlat || '',
      zavod: item.zavod,
      son: item.son,
      seriya: item.seriya,
      summa: item.narx * item.son,
    }));

    console.log("Отправляемые данные:", orderData);

    const { data, error } = await supabase.from('orders').insert(orderData);

    if (error) {
      console.error("Ошибка при отправке заказа:", error);
      alert("Ошибка при отправке заказа. Пожалуйста, попробуйте еще раз.");
    } else {
      alert("Заказ успешно отправлен!");
      setCart([]);
    }
  };

  const printCart = () => {
    setShowPrintCart(true);
  };

  return (
    <Router>
      <div className="app-container">

        {/* Условный рендеринг header */}
        {window.location.pathname !== "/Dashboard" && (
          <header className="header">
            <div className="logo-container">
              <h1>AKassa</h1>
            </div>

            <form className="search-form" onSubmit={(e) => e.preventDefault()}>
              <input 
                type="text" 
                value={searchText}
                onChange={handleSearchChange}
                ref={inputRef} 
                placeholder="Кидирув..." 
                className="search-input" 
                autoFocus 
              />
              <button type="submit" className="search-button">Искать</button>
            </form>
            
            <div className="date-container">
              <CurrentDateTime />
            </div>
            <Link to="/Dashboard" className="dashboard-button">Перейти на Dashboard</Link>
          </header>
        )}

        <Routes>
          <Route path="/" element={
            <div className="main-content">
              <div className="cart-section">
                <h2 className='carttitle'>Корзина</h2>
                <div className="cart-items">
                  {cart.length > 0 ? cart.map(item => (
                    <div key={item.id} className="cart-item">
                      <p>{item.nom} - Количество: {item.son}</p>
                      <button onClick={() => increaseQuantity(item.id)}>+</button>
                      <button onClick={() => decreaseQuantity(item.id)}>-</button>
                      <button onClick={() => removeFromCart(item.id)}>Удалить</button>
                    </div>
                  )) : <p>Корзина буш.</p>}
                </div>

                <div className="buttons-container">
                  <button className="submit-button" onClick={submitOrder}>Тулов</button>
                  <button className="print-button" onClick={printCart} style={{ marginLeft: '10px' }}>
                    Печат
                  </button>
                </div>
              </div>

              <div className="table-section" style={{ padding: '7px', backgroundColor: '#f4f4f4' }}>
                <div className="table-container" style={{ overflowY: 'auto' }}>
                  <table className="table">
                    <thead style={{ height: '50px', position: 'sticky', top: 0, background: '#ffffff', zIndex: 2 }}>
                      <tr>
                        <th className="NOMI">ТОВАР НОМИ</th>
                        <th className='SONI'>СОНИ</th>
                        <th className='NRAXI'>НАРХИ</th>
                        <th className='SROK'>ЯРОКЛИК МУДДАТИ</th>
                        <th className='DAVLAT'>ДАВЛАТ</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan="5" style={{ textAlign: 'center', padding: '20px', color: '#999' }}>Загрузка...</td>
                        </tr>
                      ) : (
                        doriData.length > 0 ? (
                          doriData.map((row) => (
                            <tr key={row.id} style={{ borderBottom: '1px solid #ddd' }}>
                              <td style={{ width: '225px', textAlign: 'center', padding: '22px', background: '#f9f9f9' }}>
                                {row.nom}
                                <button 
                                  onClick={() => addToCart(row)} 
                                  className="add-to-cart btn btn-primary" 
                                  style={{ marginLeft: '10px' }}>
                                  QUSHISH
                                </button>
                              </td>
                              <td style={{ background: '#ffffff', textAlign: 'center', padding: '12px' }}>{row.son}</td>
                              <td style={{ background: '#f9f9f9', textAlign: 'center', padding: '12px' }}>{row.price}</td>
                              <td style={{ background: '#ffffff', textAlign: 'center', padding: '12px' }}>{row.exp}</td>
                              <td style={{ background: '#f9f9f9', textAlign: 'center', padding: '12px' }}>{row.davlat}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5" style={{ textAlign: 'center', padding: '20px', color: '#999' }}>DORI TOPILMADI</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          } />

          <Route path="/Dashboard" element={<Dashboard />} />
        </Routes>

        {showPrintCart && (
          <div className="print-overlay">
            <PrintCart cart={cart} onClose={() => setShowPrintCart(false)} />
          </div>
        )}
      </div>
    </Router>
  );
};

export default App;
