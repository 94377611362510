import React from 'react';
import CurrentDateTime from './Component/CurrentDateTime';

const PrintCart = ({ cart, onClose }) => {
  const handlePrint = () => {
    window.print();
  };

  // Calculate total quantity and amount
  const totalQuantity = cart.reduce((acc, item) => acc + (item.son || 0), 0);
  const totalAmount = cart.reduce((acc, item) => acc + ((item.price || 0) * (item.son || 0)), 0);
  
  return (
    <div className="print-cart" style={{  fontSize: '10px', fontFamily: 'Arial, sans-serif', margin: '0 auto', borderRadius: '5px', backgroundColor: '#fff' }}>
     
      <div className="logo" style={{ textAlign: 'center', fontSize: '12px' }}>
        <h2 style={{ margin: '0', fontWeight: '600' }}>Тиян Фарм М.Д.С</h2>
        <h2 style={{ margin: '0', fontWeight: '600' }}>Савдо чеки</h2>
        <p style={{ fontWeight: '600' }}><CurrentDateTime /></p>
      </div>
    
      <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
        <thead>
          <tr>
            <th style={{ borderBottom: '1px solid #000', padding: '5px', textAlign: 'left', fontWeight: '600' }}>Товар</th>
            <th style={{ borderBottom: '1px solid #000', padding: '5px', textAlign: 'left', fontWeight: '600' }}>Сони</th>
            <th style={{ borderBottom: '1px solid #000', padding: '5px', textAlign: 'left', fontWeight: '600' }}>Нарх</th>
            <th style={{ borderBottom: '1px solid #000', padding: '5px', textAlign: 'left', fontWeight: '600' }}>Жами</th>
          </tr>
        </thead>
        <tbody>
          {cart.map(item => (
            <tr key={item.id}>
              <td style={{ borderBottom: '1px solid #000', padding: '5px', fontWeight: '600' }}>{item.nom}</td>
              <td style={{ borderBottom: '1px solid #000', padding: '5px', fontWeight: '600' }}>{item.son}</td>
              <td style={{ borderBottom: '1px solid #000', padding: '5px', fontWeight: '600' }}>{item.price} сум</td>
              <td style={{ borderBottom: '1px solid #000', padding: '5px', fontWeight: '600' }}>{(item.price * item.son)} сум</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div style={{ textAlign: 'right', marginTop: '10px', fontWeight: '600' }}>
        <p>Товар умумий сони: {totalQuantity}</p>
        <p>Умумий ҳисоб: {totalAmount} сум</p>
      </div>

      {/* SVG Graphic */}
      <div style={{ textAlign: 'center', marginTop: '10px' }}>
        <svg className="svg-print" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" width="0mm" height="0mm" viewBox="0 0 696 696">
          <g fill="#000000">
            {/* SVG Content Here... */}
            <g transform="translate(264,48) scale(4.12)"><rect width="6" height="6"/></g>
            <g transform="translate(264,48) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(312,48) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(336,48) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(432,48) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(288,72) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(312,72) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(384,72) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(408,72) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(432,72) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(240,96) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(264,96) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(288,96) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(384,96) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(432,96) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(240,120) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(312,120) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(384,120) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(408,120) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(240,144) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(312,144) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(384,144) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(408,144) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(240,168) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(312,168) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(360,168) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(384,168) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(432,168) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(240,192) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(288,192) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(336,192) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(384,192) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(432,192) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(240,216) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(336,216) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(360,216) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(408,216) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(48,240) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(96,240) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(120,240) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(144,240) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(168,240) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(192,240) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(264,240) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(288,240) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(312,240) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(336,240) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(384,240) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(480,240) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(504,240) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(528,240) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(552,240) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(576,240) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(48,264) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(96,264) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(120,264) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(168,264) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(216,264) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(240,264) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(312,264) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(360,264) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(432,264) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(600,264) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(48,288) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(72,288) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(96,288) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(192,288) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(216,288) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(240,288) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(288,288) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(336,288) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(384,288) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(408,288) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(456,288) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(552,288) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(600,288) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(624,288) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(72,312) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(96,312) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(120,312) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(144,312) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(168,312) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(240,312) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(264,312) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(312,312) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(384,312) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(408,312) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(432,312) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(504,312) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(624,312) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(72,336) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(96,336) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(168,336) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(192,336) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(216,336) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(240,336) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(264,336) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(288,336) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(312,336) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(336,336) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(384,336) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(456,336) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(480,336) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(504,336) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(528,336) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(576,336) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(600,336) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(624,336) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(48,360) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(72,360) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(144,360) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(168,360) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(240,360) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(288,360) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(384,360) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(504,360) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(552,360) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(600,360) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(48,384) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(96,384) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(120,384) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(192,384) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(288,384) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(312,384) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(336,384) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(360,384) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(384,384) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(432,384) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(456,384) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(504,384) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(528,384) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(552,384) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(600,384) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(624,384) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(48,408) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(96,408) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(120,408) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(264,408) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(288,408) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(312,408) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(360,408) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(384,408) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(432,408) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(456,408) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(480,408) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(504,408) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(528,408) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(624,408) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(48,432) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(96,432) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(120,432) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(144,432) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(192,432) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(216,432) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(264,432) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(312,432) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(384,432) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(432,432) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(456,432) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(480,432) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(504,432) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(528,432) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(576,432) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(240,456) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(264,456) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(336,456) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(384,456) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(408,456) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(432,456) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(528,456) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(552,456) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(360,480) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(384,480) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(432,480) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(480,480) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(528,480) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(576,480) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(600,480) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(624,480) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(240,504) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(336,504) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(360,504) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(432,504) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(528,504) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(552,504) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(624,504) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(240,528) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(264,528) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(336,528) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(360,528) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(384,528) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(408,528) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(432,528) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(456,528) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(480,528) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(504,528) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(528,528) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(576,528) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(600,528) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(240,552) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(264,552) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(288,552) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(408,552) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(456,552) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(480,552) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(528,552) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(552,552) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(576,552) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(624,552) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(240,576) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(264,576) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(288,576) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(312,576) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(336,576) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(408,576) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(552,576) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(576,576) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(624,576) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(264,600) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(312,600) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(408,600) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(432,600) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(456,600) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(480,600) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(504,600) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(528,600) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(552,600) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(624,600) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(240,624) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(264,624) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(288,624) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(312,624) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(336,624) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(408,624) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(456,624) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(480,624) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(504,624) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(528,624) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(552,624) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(576,624) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(600,624) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(624,624) scale(4.12)"><rect width="6" height="6"/></g>
<g transform="translate(48,48)"><g transform="scale(12)"><path d="M0,0v14h14V0H0z M12,12H2V2h10V12z"/></g></g>
<g transform="translate(480,48)"><g transform="scale(12)"><path d="M0,0v14h14V0H0z M12,12H2V2h10V12z"/></g></g>
<g transform="translate(48,480)"><g transform="scale(12)"><path d="M0,0v14h14V0H0z M12,12H2V2h10V12z"/></g></g>
<g transform="translate(96,96)"><g transform="scale(12)"><rect width="6" height="6"/></g></g>
<g transform="translate(528,96)"><g transform="scale(12)"><rect width="6" height="6"/></g></g>
<g transform="translate(96,528)"><g transform="scale(12)"><rect width="6" height="6"/></g></g>
          </g>
        </svg>
      </div>

      <p style={{ textAlign: 'center', margin: '0', fontWeight: '600' }}>
        Манзил: Каттакургон туман Кадан кур. 
        мулжал- Марказий шифохона енида.
      </p>
      <p style={{ textAlign: 'center', margin: '0', fontWeight: '600' }}>
        Мурожат учун:972880660    Арзон аптека # 4
      </p>

      <div style={{ textAlign: 'center', marginTop: '10px' }} className="print-buttons">
        <button onClick={onClose} style={{ width: '100%', padding: '5px', fontSize: '14px', backgroundColor: '#f44336', color: '#fff', border: 'none', borderRadius: '5px' }}>Закрыть</button>
        <button onClick={handlePrint} style={{ width: '100%', padding: '5px', fontSize: '14px', marginTop: '5px', backgroundColor: '#4CAF50', color: '#fff', border: 'none', borderRadius: '5px' }}>Печать</button>
      </div>
    
      <style jsx>{`
        @media print {
          .print-cart { 
            margin-top: 0;
          }

          .print-buttons {
            display: none; /* Скрываем кнопки при печати */
          }

          .svg-print {
            display: block; /* Показываем SVG при печати */
       width:58mm;
        height:58mm;
          }
        }



        .print-cart {
          width: 58mm; 
               }
      `}</style>
    </div>
  );
};

export default PrintCart;