import React, { useState, useEffect, useRef } from 'react';
import { supabase } from './supabaseClient';
import '../css/Dashboard.css';

const Dashboard = () => {
  const [barcode, setBarcode] = useState('');
  const [name, setName] = useState('');
  const [prname, setprname] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [expiry, setExpiry] = useState('');
  const [price, setPrice] = useState('');
  const [markup, setMarkup] = useState('');
  const [groups, setGroups] = useState([]);
  const [selectedMeasurementUnits, setSelectedMeasurementUnits] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const measurementUnits = ['УП', 'МЛ', 'ШТ'];
  const pharmaceuticalGroups = [
    'Антибиот.',                   // Antibiotiklar
    'Противовир.',                 // Virusga qarshi
    'Обезбол.',                     // Ağrı kesici
    'Противовосп.',                // Yallig‘lanishga qarshi
    'Антидепр.',                   // Antidepresantlar
    'Сердечно-сосуд.',             // Yurak-qon tomir
    'Гормонал.',                   // Gormonlar
    'Противоаллерг.',              // Allergiyaga qarshi
    'Седатив.',                     // Sedativ
    'Противогрибк.',               // Qo‘ziqorinlarga qarshi
    'Гастроинт.',                  // Gastrointestinal
    'Иммуномод.',                  // Immunomodulyatorlar
    'Антигиперт.'                  // Gipertoniya qarshi
  ];
  
  const debounceTimeout = useRef(null);

  useEffect(() => {
    if (!barcode) {
      resetForm();
      return;
    }

    clearTimeout(debounceTimeout.current);
    debounceTimeout.current = setTimeout(async () => {
      const { data, error } = await supabase
        .from('product')
        .select('*')
        .eq('barcode', barcode)
        .single();

      if (error) {
        if (error.code === 'PGRST116') {
          setErrorMessage('Продукт не найден');
        } else {
          setErrorMessage('Ошибка при загрузке данных');
        }
        resetForm();
      } else {
        setErrorMessage('');
        setName(data.name);
        setprname(data.prname.split('_')[1] || '');
        setQuantity(data.grkol);
        setSelectedMeasurementUnits([data.edizm]);
      }
    }, 30);

    return () => clearTimeout(debounceTimeout.current);
  }, [barcode]);

  const handleBarcodeChange = (e) => {
    setBarcode(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!quantity || !price || !markup || !expiry) {
      setErrorMessage('Количество, цена, наценка и срок годности должны быть заполнены.');
      return;
    }

    const { data: existingProduct, error: fetchError } = await supabase
      .from('dori')
      .select('*')
      .eq('kod', parseInt(barcode, 10))
      .single();

    if (fetchError) {
      if (fetchError.code !== 'PGRST116') {
        console.error('Ошибка при поиске товара:', fetchError);
        setErrorMessage('Ошибка при поиске товара');
        return;
      }
    }

    if (existingProduct) {
      const isExpiryChanged = existingProduct.exp !== (expiry ? new Date(expiry).toISOString().split('T')[0] : null);
      const isPriceChanged = existingProduct.narx !== parseFloat(price);

      if (isExpiryChanged || isPriceChanged) {
        const newPayload = {
          nom: name,
          gruppa: groups.join(', '),
          davlat: prname,
          zavod: '',
          son: quantity,
          kod: parseInt(barcode, 10),
          narx: parseFloat(price),
          foyz: parseFloat(markup),
          exp: expiry ? new Date(expiry).toISOString().split('T')[0] : null,
        };

        const { error: insertError } = await supabase
          .from('dori')
          .insert([newPayload]);

        if (insertError) {
          console.error('Ошибка при добавлении товара:', insertError);
          setErrorMessage('Ошибка при добавлении товара');
        } else {
          alert('Товар успешно добавлен с изменениями!');
          resetForm();
        }
      } else {
        const updatedQuantity = existingProduct.son + quantity;

        const { error: updateError } = await supabase
          .from('dori')
          .update({ son: updatedQuantity })
          .eq('kod', parseInt(barcode, 10));

        if (updateError) {
          console.error('Ошибка при обновлении товара:', updateError);
          setErrorMessage('Ошибка при обновлении товара');
        } else {
          alert('Количество товара успешно обновлено!');
          resetForm();
        }
      }
    } else {
      const payload = {
        nom: name,
        gruppa: groups.join(', '),
        davlat: prname,
        zavod: '',
        son: quantity,
        kod: parseInt(barcode, 10),
        narx: parseFloat(price),
        foyz: parseFloat(markup),
        exp: expiry ? new Date(expiry).toISOString().split('T')[0] : null,
      };

      const { error: insertError } = await supabase
        .from('dori')
        .insert([payload]);

      if (insertError) {
        console.error('Ошибка при добавлении товара:', insertError);
        setErrorMessage('Ошибка при добавлении товара');
      } else {
        alert('Товар успешно добавлен!');
        resetForm();
      }
    }
  };

  const resetForm = () => {
    setBarcode('');
    setName('');
    setprname('');
    setQuantity(1);
    setExpiry('');
    setPrice('');
    setMarkup('');
    setGroups([]);
    setSelectedMeasurementUnits([]);
    setErrorMessage('');
  };

  const handleGroupChange = (e) => {
    const value = e.target.value;
    setGroups((prev) =>
      prev.includes(value) ? prev.filter(group => group !== value) : [...prev, value]
    );
  };

  const handleMeasurementUnitChange = (unit) => {
    setSelectedMeasurementUnits((prev) =>
      prev.includes(unit) ? prev.filter(u => u !== unit) : [...prev, unit]
    );
  };

  return (
    <div className="dashboard-container">
      <header className="dashboard-header">
        {/* <h1>Дашборд Менеджера</h1> */}
      </header>
      <div className="dashboard-content">
        <nav className="dashboard-sidebar">
          <ul>
            <li>Главная</li>
            <li>Добавить лекарства</li>
            <li>Просмотреть лекарства</li>
            <li>Отчеты</li>
            <li>Настройки</li>
            <li>Выход</li>
          </ul>
        </nav>
        <main className="dashboard-main">
          <h2>Добавить Лекарства</h2>
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="barcode">Штрих-код:</label>
              <input 
                type="text" 
                id="barcode" 
                value={barcode} 
                autoFocus 
                onChange={handleBarcodeChange} 
                required 
              />
            </div>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            <div>
              <label htmlFor="name">Название:</label>
              <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} required />
            </div>
            <div>
              <label htmlFor="prname">Страна:</label>
              <input 
                type="text" 
                id="prname" 
                value={prname} 
                onChange={(e) => setprname(e.target.value)} 
              />
            </div>
            <div>
              <label htmlFor="quantity">Количество:</label>
              <input type="number" id="quantity" value={quantity} onChange={(e) => setQuantity(parseInt(e.target.value, 10))} required />
            </div>
            <div>
              <label>Единицы измерения:</label>
              <div   className="checkbox">
                {measurementUnits.map(unit => (
                  <label key={unit}>
                    <input
                   
                      type="checkbox" 
                      value={unit} 
                      checked={selectedMeasurementUnits.includes(unit)} 
                      onChange={() => handleMeasurementUnitChange(unit)} 
                    />
                    {unit}
                  </label>
                ))}
              </div>
            </div>
            <div>
              <label htmlFor="expiry-date">Срок годности:</label>
              <input type="date" id="expiry-date" value={expiry} onChange={(e) => setExpiry(e.target.value || null)} required />
            </div>
            <div>
              <label htmlFor="price">Цена:</label>
              <input type="number" id="price" value={price} onChange={(e) => setPrice(parseFloat(e.target.value))} required />
            </div>
            <div>
              <label htmlFor="markup">Наценка (%) :</label>
              <input type="number" id="markup" value={markup} onChange={(e) => setMarkup(parseFloat(e.target.value))} required />
            </div>
           
            <button type="submit">Добавить</button>
          </form>
        </main>

      </div>
    </div>
  );
};

export default Dashboard;
